import { useState } from 'react';

import { NewIcon, Spacer } from 'design-system';
import { AnimatePresence, motion } from 'framer-motion';
import Link from 'next/link';
import { useRouter } from 'next/router';

import {
  COMPANY_INFO_TEXT,
  TGS_DISCLAIMER_TEXT,
  companyInfo,
  iconLinks,
  termsLinks,
} from '@constants';
import { isAppApproaching } from '@utils/Common';

const isAppApproach = isAppApproaching();

export const Footer = () => {
  const router = useRouter();

  const { service, location, privacy, partnership } = termsLinks;

  const [isInfoOpen, setIsInfoOpen] = useState<boolean>(true);

  return (
    <article className="bg-gray-150 py-42 flex flex-col px-16">
      <h1
        onClick={() => {
          setIsInfoOpen((prev) => !prev);
        }}
        className="text-Body1 flex cursor-pointer items-center gap-[4px] pb-12 text-gray-600"
      >
        {companyInfo.name}
        <motion.figure animate={{ rotate: isInfoOpen ? 180 : 0 }}>
          <NewIcon icon="arrowdown-outlined" size={16} className="fill-new-gray-400" />
        </motion.figure>
      </h1>
      <AnimatePresence initial={true}>
        {isInfoOpen && (
          <motion.section
            key="content"
            // ssr에서 height 값을 주기 위해 initail 값을 open으로 설정
            initial="open"
            animate="open"
            exit="collapsed"
            variants={{
              open: { opacity: 1, height: 'auto' },
              collapsed: { opacity: 0, height: 0 },
            }}
            transition={{ type: 'spring', bounce: 0, duration: 0.5 }}
          >
            <div className="flex flex-col">
              <div className="text-Detail2 text-new-gray-500 whitespace-pre">
                {COMPANY_INFO_TEXT}
              </div>
            </div>
            <Spacer className="h-12" />
          </motion.section>
        )}
      </AnimatePresence>
      <section className={`w-full break-keep ${isInfoOpen && 'pt-12'} pb-12`}>
        <Link href={service.href} className="text-Body6 text-gray-600"></Link>
        <section className={'flex pb-4'}>
          <Link href={service.href} className="text-new-Body2-bold text-new-gray-500">
            {service.label}
            <span className="text-Detail1 px-8 text-gray-300">|</span>
          </Link>
          <Link href={location.href} className="text-new-Body2-bold text-new-gray-500">
            {location.label}
            <span className="text-Detail1 px-8 text-gray-300">|</span>
          </Link>
        </section>
        <section className={'flex pb-12'}>
          <Link href={privacy.href} className="text-new-Body2-bold text-new-gray-500">
            {privacy.label}
            <span className="text-Detail1 px-8 text-gray-300">|</span>
          </Link>
          <Link href={partnership.href} legacyBehavior passHref>
            <a target="_blank" className="text-new-Body2-bold text-new-gray-500">
              {partnership.label}
            </a>
          </Link>
        </section>
      </section>
      <div className="text-Detail2 text-gray-400">{TGS_DISCLAIMER_TEXT}</div>
      <div className="mt-18 gap-18 flex">
        {iconLinks.map((link) => (
          <div
            key={link.href}
            onClick={() => {
              if (!link.openNewWindow) {
                router.push(link.href);
                return;
              }
              if (isAppApproach) {
                window.flutter_inappwebview.callHandler(
                  'ScreenMove',
                  'goToExternal',
                  link.name,
                  link.href,
                );
                return;
              }
              window.open(link.href, '_blank');
            }}
            className="bg-new-gray-200 flex h-40 w-40 items-center justify-center rounded-full"
          >
            {link.icon}
          </div>
        ))}
      </div>
    </article>
  );
};
